import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo/Title */}
        <h1
          className="text-2xl font-bold text-transparent bg-clip-text 
          bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600"
        >
          Class Monthly
        </h1>
        
        {/* Navigation */}
        <nav>
          <Link
            to="/"
            className="mx-2 text-white hover:text-purple-300 transition"
          >
            Home
          </Link>
          {/* Uncomment when Categories Page is implemented */}
          {/* <Link
            to="/categories"
            className="mx-2 text-white hover:text-purple-300 transition"
          >
            Categories
          </Link> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;